@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	h1 {
		@apply text-4xl font-extrabold
	}

	h2 {
		@apply text-2xl font-semibold
	}

	h3,
	h4,
	h5,
	h6 {
		@apply text-xl font-semibold
	}
	p{
		@apply tracking-widest leading-7
	}
	
  body{
    @apply bg-primary-bg tracking-widest
  }
	 input::placeholder {
        @apply text-gray-200;
    }

    input:-ms-input-placeholder {
        @apply text-gray-200;
    }

    input::-ms-input-placeholder {
        @apply text-gray-200;
    }
}

@layer utilities {
	@variants responsive {
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}
		.no-scrollbar {
			-ms-overflow-style: none;
			scrollbar-width: none;
		}
		.scrolling-touch {
			-webkit-overflow-scrolling: touch;
		}
	}
}

html[lang="ja"] {
	font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
  }
/* top page tab border */
.tab-border>button{
	border-right: 1px solid #EEEEEE;
	width: 50%;
}

.tab-border>button:last-child{
	border-right: 0px;
}
ul.square,
ul.dimond,
ul.star
{
	position: relative;
}
ul.square>li,
ul.dimond li,
ul.star li{
	margin-left:25px;
}
ul.square li::before,
ul.dimond li::before,
ul.star li::before{
  	position: absolute;
	left: 0;
	padding-left: 5px;
	color: #FEF29D;
}
ul.square li::before{
  	content: "■";
}
ul.dimond li::before {
  content: "◆";
}
ul.star li::before {
  content: "※";
}
ul.dimond li:nth-child(odd)::before {
	color: #FEF29D;
}
ul.dimond li:nth-child(even)::before {
   color: #F2921C;
}

select>option:disabled{
	color: #2f855a;
}